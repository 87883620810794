:root {
  /* Global */
  --borderRadius: 5px;
  --before: rgb(85, 85, 85);
  /* Light Mode */
  --mainText: #2c2c2c;
  --containerColor: #c4c4c4;
  --containerColorPsuedo: #cdcdcd;
  --containerSecondColor: #6e6d6d;
  --navColor: #d0d0d0;

  /* Dark Mode */
  --mainDarkText: #d8d8d8;
  --containerDarkColor: #131313;
  --containerDarkColorPsuedo: #3a3a3a;
  --containerDarkSecondColor: #ffffff;
  --navDarkColor: #3d3d3d;
}

.line-container {
  text-align: center;
  overflow: hidden;
}

svg {
  position: absolute;
  width: 100%;
  display: inline-block;
  height: 4739px;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  transition: 350ms;
  background-image: url("../public/assets/images/background-image.png");
  /* border: 3px solid red; */
}

/* nkjfdnv */

* {
  margin: auto;
  font-size: 16px;
  max-width: 1500px;
}

::selection {
  background-color: #a19e9e;
  color: white;
  stroke-width: 5px;
  stroke: black;
  border-radius: 30px;
}

header {
  /* border: 3px solid red; */
  padding: 20px 0px;
  display: flex;
}

header h1 {
  font-size: 38px;
  color: #434343;
}

.dark header h1 {
  font-size: 38px;
  color: #cacaca;
}

.theme {
  height: 80px;
  width: 100px;
  background-color: var(--navColor);
  border-radius: var(--borderRadius);
  margin-left: 20px;
}

.theme::before {
  content: "";
  background-color: var(--before);
  height: 80px;
  width: 100px;
  border: 1px solid rgb(73, 73, 73);
  position: absolute;
  margin: 5px 20px 0 5px;
  border-radius: var(--borderRadius);
  z-index: -1;
}

.dark .theme {
  background-color: var(--navDarkColor);
}

.theme img {
  width: 70px;
  display: block;
  margin: auto;
  margin-top: 5px;
}

.greetings {
  /* border: 3px solid rgb(87, 153, 90); */
  margin: 150px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.greetings > .section__1 {
  flex: 1;
}

.hello {
  width: 50%;
}

.picture {
  width: 50%;
  animation: MoveUpDown 4s linear infinite;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 50px;
  }
}

.hello h1 {
  color: var(--mainText);
  /* border: 3px solid rgb(77, 224, 85); */
  font-size: 7rem;
}

.dark .hello h1 {
  color: var(--mainDarkText);
}

.right__text h1 {
  color: var(--mainText);
  margin-bottom: 80px;
  width: 400px;
  font-size: 5rem;
  text-align: center;
}

.dark .right__text h1 {
  color: var(--mainDarkText);
}

.my__picture {
  height: 500px;
  width: 60%;
  border-radius: var(--borderRadius);
  margin: 0% 0% 0% 30%;
  background-size: cover;
  background-position: center;
  min-width: 400px;
}

.my__picture::before {
  content: "";
  background-color: var(--before);
  height: 500px;
  width: 60%;
  border: 1px solid rgb(73, 73, 73);
  position: absolute;
  margin-top: 10px;
  margin-left: -10px;
  border-radius: var(--borderRadius);
  z-index: -1;
}

.section__1 {
  position: relative;
}

.section__1 img {
  position: absolute;
  height: 230px;
  z-index: -1;
  margin-left: 36%;
  margin-top: -120px;
  transform: rotate(50deg);
}

.skills {
  margin: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  position: relative;
}

.card {
  border-radius: var(--borderRadius);
  padding: 20px;
  margin-left: 20px;
  display: inline-flex;
  flex-direction: column;
}

.card h1 {
  color: var(--mainText);
  font-size: 3rem;
  font-style: normal;
}

.dark .card h1 {
  color: var(--mainDarkText);
}

.skillsList {
  display: inline-flex;
  flex-wrap: wrap;
}

.skill {
  background-color: rgb(233, 233, 233);
  width: 100px;
  height: 150px;
  border-radius: var(--borderRadius);
  margin: 20px 0px 20px 20px;
  transform: translateX(40deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.skill::before {
  content: "";
  background-color: var(--before);
  height: 150px;
  width: 100px;
  border: 1px solid rgb(73, 73, 73);
  position: absolute;
  margin: 20px 10px 0 0;
  border-radius: var(--borderRadius);
  z-index: -1;
}

.dark .skill {
  background-color: var(--containerDarkSecondColor);
}

.projects {
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.peerlist {
  display: flex;
  align-items: center;
  position: relative;
}

.peerlist img {
  width: 800px;
}

.peerlist a {
  background-color: rgb(0, 134, 0);
  color: white;
  font-weight: bold;
  border-radius: var(--borderRadius);
  text-decoration: none;
  margin: 40px 0px;
  width: 70%;
  height: 68px;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
}

.peerlist a::before {
  content: "";
  background-color: var(--before);
  width: 52%;
  height: 68px;
  border: 1px solid rgb(73, 73, 73);
  position: absolute;
  margin: 20px 10px 0 0;
  border-radius: var(--borderRadius);
  z-index: -1;
}

.resume {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: 200px;
}

.resume__container {
  background-color: var(--containerColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
}

.resume__container::before {
  content: "";
  background-color: var(--before);
  width: 340px;
  height: 640px;
  border: 1px solid rgb(73, 73, 73);
  position: absolute;
  margin: 40px 140px 0 120px;
  border-radius: var(--borderRadius);
  z-index: -1;
}

.dark .resume__container {
  background-color: var(--containerDarkColor);
}

.resume__img {
  width: 900px;
  height: 500px;
  border-radius: var(--borderRadius);
  margin: 20px;
  background-size: cover;
  max-width: 300px;
}

.resume__container a {
  background-color: var(--containerSecondColor);
  color: white;
  font-weight: bold;
  border-radius: var(--borderRadius);
  text-decoration: none;
  margin: 20px;
  width: 200px;
  height: 68px;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .resume__container a {
  background-color: var(--containerDarkSecondColor);
  color: var(--containerDarkColor);
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: 200px;
}

.about hr {
  border: none;
  background-color: gray;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 20px 20px;
}

.about__me {
  width: 50%;
  border-radius: var(--borderRadius);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 20px;
}

.about__me img {
  width: 150px;
  height: 150px;
  border-radius: var(--borderRadius);
}

.about__me p {
  color: var(--mainText);
  font-size: 1.7rem;
  font-weight: bold;
  text-align: left;
}

.dark .about__me p {
  color: var(--mainDarkText);
  font-size: 1.7rem;
  font-weight: bold;
  text-align: left;
}

.about__me__text {
  background-color: #eeeeee;
  border-radius: var(--borderRadius);
  margin: 20px 0px 0px 0px;
  padding: 20px;
}

.dark .about__me__text {
  background-color: rgb(58, 58, 58);
  border-radius: var(--borderRadius);
  margin: 20px 0px 0px 0px;
  padding: 20px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  margin-bottom: 40px;
}

.footer a {
  background-color: var(--containerSecondColor);
  color: white;
  font-weight: bold;
  border-radius: var(--borderRadius);
  text-decoration: none;
  margin: 40px;
  width: 70%;
  height: 68px;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .footer a {
  background-color: var(--containerDarkSecondColor);
}

.footer h1 {
  font-size: 70px;
  color: var(--mainText);
}

.dark .footer h1 {
  color: var(--mainDarkText);
}

.footer p {
  color: var(--mainText);
}

.dark .footer p {
  color: var(--mainDarkText);
}

@media (max-width: 1400px) {
  .hello h1 {
    font-size: 4rem;
  }
  .dark .hello h1 {
    font-size: 4rem;
  }
  .right__text h1 {
    color: var(--mainText);
    width: 300px;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 50px;
  }
  .card h1 {
    color: var(--mainText);
    font-size: 2rem;
    font-style: normal;
  }
}

@media (max-width: 1000px) {
  body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    margin: 2px;
    transition: 350ms;
  }
  .hello {
    width: 100%;
    align-items: center;
  }
  .hello h1 {
    font-size: 3rem;
    text-align: center;
  }
  .dark .hello h1 {
    font-size: 3rem;
    text-align: center;
  }
  .greetings {
    margin: 40px 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .picture {
    width: 100%;
    margin-top: 50px;
  }
  .my__picture {
    height: 400px;
    width: 60%;
    min-width: 300px;
    margin: 80px auto 0px auto;
  }
  .my__picture::before {
    height: 400px;
  }
  .section__1 img {
    margin-left: 15%;
    margin-top: -50px;
    transform: rotate(40deg);
  }
  .skills {
    margin: 100px 20px;
  }
  .card {
    display: inline-block;
  }
  .card::before {
    width: 239px;
    height: 1079px;
  }
  .dark .card::before {
    width: 239px;
    height: 1079px;
  }
  .projects {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin: -20px; */
  }
  .peerlist {
    flex-direction: column;
  }
  .peerlist img {
    width: 350px;
  }
  .peerlist a::before {
    width: 82%;
    height: 68px;
    margin: 10px 15px 0 0;
  }
  .resume {
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    flex-direction: column;
  }
  .about {
    display: flex;
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .about__me {
    width: 80%;
    min-width: 300px;
  }
  .footer button {
    background-color: var(--containerSecondColor);
    color: white;
    font-weight: bold;
    font-size: 2rem;
    border: none;
    border-radius: 30px;
    width: 60%;
    height: 80px;
  }
  .dark .footer button {
    background-color: var(--containerDarkSecondColor);
    color: white;
    font-weight: bold;
    font-size: 2rem;
    border: none;
    border-radius: 30px;
    width: 60%;
    height: 80px;
  }
}
